/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { resetButtonStyle } from "../commonStyles";

type Props = {
  onClick: () => void;
};

const button = css`
    ${resetButtonStyle};
    cursor: pointer;
`

const Close = ({ onClick }: Props) => {
  return (
    <button css={button} onClick={onClick} aria-label="Close">
      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48" aria-hidden>
        <path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z" />
      </svg>
    </button>
  );
};

export default Close;
