/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { commonButtonStyle } from "../commonStyles";
import { trackEvent } from "../api";

type Props = {
  title: string;
  videoUrl: string;
  activityId: number;
};

const video = css`
  width: 100%;
  display: block;
  aspect-ratio: 1 / 1;
  background-color: #333333;
`;

const container = css``;

const innerContainer = css`
  padding: 10px;
`;

const buttonContainer = css`
  display: flex;
  justify-content: flex-end;
`;

const downloadButtonStyle = () => css`
  ${commonButtonStyle(false)};
  color: #9485d2;
  background-color: transparent;
`;

const getLabel = (failed: boolean, shared: boolean) => {
  if (failed) {
    return "Sharing failed";
  }
  if (shared) {
    return "Shared";
  }
  return "Share via...";
};

const ShareBox = ({ title, videoUrl, activityId }: Props) => {
  const [failed, setFailed] = useState(false);
  const [shared, setShared] = useState(false);
  const handleLinkClick = () => {
    trackEvent('Download video', {
      activityId
    })
  }
  const handleShareClick = async () => {
    try {
      const file = await fetch(videoUrl);
      const blob = await file.blob();

      await navigator.share({
        title: title,
        text: "Activity video",
        files: [new File([blob], "activity.mp4", { type: "video/mp4" })],
      });
      setShared(true);
      trackEvent('Share success', {
        activityId
      })
    } catch (error: any) {
      if (error?.name !== "AbortError") {
        setFailed(true);
      }
      trackEvent('Share error', {
        activityId,
        error: error?.name,
      })
    }
  };

  return (
    <div css={container}>
      <div>
        <video loop autoPlay css={video} playsInline>
          <source src={videoUrl} type="video/mp4" />
        </video>
        <div css={innerContainer}>
          <div css={buttonContainer}>
            <a
              onClick={handleLinkClick}
              href={videoUrl}
              css={downloadButtonStyle}
              target="_blank"
              rel="noreferrer noopener"
            >
              Download
            </a>
            <button
              onClick={handleShareClick}
              css={commonButtonStyle(failed, true)}
              disabled={failed}
            >
              {getLabel(failed, shared)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareBox;
