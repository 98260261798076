/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import paintMoveLogo from "./paintmove.svg";
import stravaConnectImage from "./strava-connect.svg";

const containerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
`;

const loginButtonStyle = css`
`;

const privacyStatement = css`
  font-size: 12px;
  a {
    color: inherit;
  }
`


const StartScreen = () => {
  return (
    <div css={containerStyle}>
      <h1>
        <img src={paintMoveLogo} alt="PainMove" />
      </h1>
      <p>Connect your Strava account with PaintMove <br /> and create 3D animations of your activities.</p>
      <a
        css={loginButtonStyle}
        href={`https://www.strava.com/oauth/authorize?client_id=${process.env.REACT_APP_STRAVA_CLIEND_ID}&redirect_uri=${process.env.REACT_APP_SERVER_ADDRESS}/auth&response_type=code&approval_prompt=auto&scope=activity:read`}
        rel="noreferrer noopener"
      >
        <img src={stravaConnectImage} alt="Connect with Strava" />
      </a>
      <p css={privacyStatement}>By creating an account you agree to <a href="https://paintmove.com/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a></p>
    </div>
  );
};

export default StartScreen;
