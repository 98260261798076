/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import poweredByStrava from "../powered-by-strava-dark.svg";

const stravaTrademark = css`
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
  width: 100px;
`;

const StravaTrademark = () => (
  <img src={poweredByStrava} alt="Powered by Strava" css={stravaTrademark} />
);

export default StravaTrademark
