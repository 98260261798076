import {Activity, PointData, RequestVideoResponse, City} from '../../types/'
const API_URL = process.env.REACT_APP_SERVER_ADDRESS;

type Options = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
}

const requestApi = async <T>(method: String, payload: object, options: Options = {}) => {
  const requestParams = {
    method: options.method || "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }
  if (options.method !== 'GET') {
    requestParams.body =  JSON.stringify(payload)
  }
  const res = await fetch(`${API_URL}/${method}`, requestParams);
  if (res.status === 429) {
    throw new Error("Rate Limit");
  }
  if (res.status !== 200) {
      throw new Error(`API error: ${res.status}} ${JSON.stringify(await res.json())}`, );
  }
  const data = await res.json();
  return data as T
};



export const getPolylineData = (activityId: number) => {
    return requestApi<{data: PointData[], tiles: PointData[][], activity: Activity, nearestCities: City[]}>('altitudes', {activityId})
}

export const getActivities = (page: number) => {
    return requestApi<{activities: Activity[]}>('activities', {page})
}

export const requestVideo = (activityId: number, features?: {[key: string]: boolean}) => {
    return requestApi<RequestVideoResponse>('request-video', {activityId, features})
}

export const getVideos = (activityId: number) => {
    return requestApi<{videos: RequestVideoResponse[]}>('videos', {activityId})
}

export const trackEvent = (name: string, properties: Record<string, string | number>) => {
  return requestApi('event', {name, properties}).catch(() => {})
}

export const updateEmail = (email: string) => {
  return requestApi('user/update-email', {email})
}

export const getUser = () => {
  return requestApi('user', {}, {
    method: 'GET'
  })
}

export const getVideo = (videoId: string) => {
  return requestApi<RequestVideoResponse>(`video?id=${videoId}`, {}, {
    method: 'GET'
  })
}
