/** @jsxImportSource @emotion/react */
import * as React from "react";
import { css } from "@emotion/react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import Close from "./Close";

type Props = {
  children: React.ReactNode;
  show: boolean;
  close: () => void;
  title?: string;
};

const overlayStyle = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const modalStyle = css`
  position: fixed;
  width: 90%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 10px;
`;

const header = css`
  font-size: 16px;
  margin: 8px 0;
`;

const top = css`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const Modal = ({ children, show, close, title }: Props) => {
  return (
    <DialogOverlay css={overlayStyle} isOpen={show} onDismiss={close}>
      <DialogContent css={modalStyle}>
        <div css={top}>
          {title && <h2 css={header}>{title}</h2>}
          <Close onClick={close} />
        </div>
        {children}
      </DialogContent>
    </DialogOverlay>
  );
};

export default Modal;
