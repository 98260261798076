const Lights = () => {
    return (
        <>
            <ambientLight intensity={4} />
            <directionalLight position={[10000, 10000, 0]} />
            <directionalLight position={[-10000, 100, 10000]} color="#fff" intensity={3} />
            <directionalLight position={[-20000, 2000, 20000]} />
        </>
    )
}

export default Lights
