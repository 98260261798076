/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from "react";
import Canvas from "./components/Canvas";
import Visualization from "./components/Visualization";
import { WebGLRenderer, Vector3 } from "three";
import { css } from "@emotion/react";
import {getPolylineData, getVideo} from './api'
import {Activity, PointData, City, RequestVideoResponse}  from '../../types'
import { Text, Hud, PerspectiveCamera } from "@react-three/drei";
import * as formatters from './utils/formatters'

type Props = {
  videoId: string;
};

const canvasContainerStyle = css`
  flex-grow: 1;
  background-color: #333;
  flex-shrink: 1;
  min-width: 0px;
`;

const containerStyle = css`
  display: flex;
  align-items: stretch;
  height: 100%;
`;

const titlePosition = new Vector3(-12.5, 11, 0);
const placePosition = new Vector3(-12.5, 8.5, 0);
const metricsPosition = new Vector3(-12.5, 7.5, 0);
const trademarkPosition = new Vector3(13, -13, 0);

const VideoRender = ({ videoId }: Props) => {
  const rendererRef = useRef<WebGLRenderer | null>(null);
  const [data, setData] = useState<PointData[] | null>(null);
  const [tiles, setTiles] = useState<PointData[][] | null>(null);
  const [videoData, setVideoData] = useState<RequestVideoResponse | null>(null);
  const [activityData, setActivityData] = useState<Activity|null>(null)
  const [nearestCities, setNearestCities] = useState<City[] | null>(null);
  const [generatingVideo, setGeneratingVideo] = useState(true);
  useEffect(() => {
    getVideo(videoId).then((data) => {
      setVideoData(data);
      getPolylineData(parseInt((data.activityId), 10)).then((data) => {
        setData(data.data);
        setTiles(data.tiles);
        setActivityData(data.activity)
        setNearestCities(data.nearestCities)
      });
    });
  }, []);
  return (
    <div css={containerStyle}>
      <div css={canvasContainerStyle}>
        <Canvas>
          {data && tiles && videoData && (
            <>
              <Hud>
                <Visualization
                  tiles={tiles}
                  data={data}
                  rendererRef={rendererRef}
                  autoDownload={false}
                  generatingVideo={generatingVideo}
                  onVideoFinish={() => setGeneratingVideo(false)}
                  nearestCities={nearestCities || undefined}
                  drawLabels={videoData?.features?.drawLabels}
                />
              </Hud>
              <Hud renderPriority={2}>
                <group>
                  <PerspectiveCamera makeDefault position={[0, 0, 30]} />
                  <Text font="/Inter-Regular.woff" fontSize={1.3} color="#9e9e9e" position={titlePosition} anchorX="left" anchorY="top-baseline" maxWidth={25}>
                    {activityData?.name}
                  </Text>
                  <Text font="/Inter-Regular.woff" fontSize={.9} color="#757575" position={placePosition} anchorX="left" anchorY="top-baseline">{activityData?.nearestCity}</Text>
                  <Text font="/Inter-Regular.woff" fontSize={.9} color="#757575" position={metricsPosition} anchorX="left" anchorY="top-baseline">{formatters.formatDistance(activityData?.distance!)}  •  {formatters.formatMovingTime(activityData?.movingTime!)}</Text>
                  <Text
                    font="/Inter-Regular.woff"
                    fontSize={0.8}
                    color="#9e9e9e"
                    position={trademarkPosition}
                    anchorX="right" anchorY="bottom-baseline"
                  >
                    Powered by PaintMove.com
                  </Text>
                </group>
              </Hud>
            </>
          )}
        </Canvas>
      </div>
    </div>
  );
};

export default VideoRender;
