export const themes = [
    {
        name: 'green',
        background: "12241b",
        lightColor: '38664f',
        darkColor: '000000',
        path: 'ffffff',
        dot: 'cdcdcd',
        text: 'fff',
        marks: 'bababa',
    },
    {
        name: 'orange',
        background: "000",
        lightColor: 'fc6603',
        darkColor: '000',
        path: 'bdaea4',
        dot: 'bdaea4',
        text: 'fff',
        marks: 'bdaea4',
    },
    {
        name: 'grey',
        background: "c9c9c9",
        lightColor: 'bfbfbf',
        darkColor: '4d4d4d',
        path: 'eb5a00',
        dot: 'eb5a00',
        text: '000',
        marks: 'eb5a00',
    }
]

export type Theme = typeof themes[0]
