import { css } from "@emotion/react";

export const resetButtonStyle = css`
  appearance: none;
  border: 0;
  font-size: inherit;
  font-family: inherit;
  text-decoration: none;
  background: transparent;
  padding: 0;
`;

export const commonButtonStyle = (pending: boolean, primary?: boolean) => css`
  ${resetButtonStyle};
  border-radius: 12px;
  padding: 10px 15px;
  line-height: 1.2em;
  display: inline-block;
  border: 1px solid transparent;
  opacity: ${pending ? "0.8" : "1"};
  cursor: ${pending ? "wait" : "pointer"};

  ${primary &&
  css`
    color: #fff;
    background-color: #7158d3;
  `}
`;
