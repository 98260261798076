import App from "./App";
import VideoRender from "./VideoRender";
import { ToastContextProvider } from "./components/Toasts";

const urlParams = new URLSearchParams(window.location.search);
const videoIdParam = urlParams.get("video_id");

const Router = () => {
  return (
    <ToastContextProvider>
      {videoIdParam && (
        <VideoRender videoId={videoIdParam as string} />
      )}
      {!videoIdParam && <App />}
    </ToastContextProvider>
  );
};

export default Router;
