import {
    Vector3,
} from "three";
import { Line } from "@react-three/drei";
import { Theme } from "../../../themes";

type Props = {
    tilesMappedToPoints: Vector3[][],
    tilesMappedToPoints2: Vector3[][],
    gridFilterFactor: number
    theme: Theme
}

const Grid = ({ tilesMappedToPoints, tilesMappedToPoints2, gridFilterFactor, theme }: Props) => {
    return (
        <>
            {tilesMappedToPoints
                .filter((_, index) => index % gridFilterFactor === 0)
                .map((points, index) => (
                    <>
                        <Line
                            points={points}
                            color={`#${theme.lightColor}`}
                            lineWidth={.5}
                            key={index}
                            depthWrite={false}
                            renderOrder={100}
                        />
                    </>
                ))}

            {tilesMappedToPoints2
                .filter((_, index) => index % gridFilterFactor === 0)
                .map((points, index) => (
                    <>
                        <Line
                            points={points}
                            color={`#${theme.lightColor}`}
                            lineWidth={.5}
                            key={index}
                            depthWrite={false}
                            renderOrder={100}
                        />
                    </>
                ))}
        </>
    )
}

export default Grid
