import React, { useEffect, useMemo } from "react";
import {
  Vector3,
  Mesh,
  CatmullRomCurve3,
  PerspectiveCamera as RawPerspectiveCamera,
} from "three";
import { Encoder } from "../../encoder";
import { useFrame, useThree } from "@react-three/fiber";
import {
  Tube,
  PerspectiveCamera,
  Bounds,
} from "@react-three/drei";
import { PointData, City } from "types";
import { OrbitControls } from "../OrbitControls";
import { themes, Theme } from "../../themes";
import Grid from "./components/Grid";
import Cities from "./components/Cities";
import Lights from "./components/Lights";
import PanthEnd from "./components/PathEnd";
import PositionPointer from "./components/PositionPointer";
import Surface from "./components/Surface";
import { angleToRad } from "../../utils/math";

// const samplePolyline =
// ("apsvHw|yfBTXb@EHBJNMv@A@Rt@g@dFFJb@H@JsAlO]jFEP]`@GJD`@L`@?`@Il@WrCa@nHC~@UjDYbDMzBOtAgAvGm@dFOx@Ut@OdBGVMF@b@k@\\YZCJj@bBNl@JRHp@L^n@dDPf@l@`Ef@`Bh@nDCtA\\tCATDn@h@|Bf@vCXjADP?TDTbBzFPfAFH^LRVXf@jArC\\h@xAzCf@lAZbAFNb@PTZbCrFbA`D`@n@rA`DBPARSzAg@zAa@p@I`@?d@F`@Vv@FBb@CFFBNC`@R|BBp@Z~CBNLVHlA?p@L~@ZjD`@xEFn@JXBNDp@?r@NdBDdA@hCK|BQdBKZ?^K\\In@@jBUZGP?JBL`@`@^j@dExFlB~C`AlBDJANK\\E\\?pAN|@JVPVZVh@HTA`@MZ]LYL]RaAFMVGVDlB?l@HJE\\H|BC\\DX\\RLrBDj@GxA@~@Df@N`AJb@E|HTH@RRHBJANUHCjAJXHxDjB`@LZZfP~JPNBJQt@Gl@wC`e@]~D@LFFJB^CXDzEbB~S`Hx@ZTNxE~Aj@VX^J?TSHA`@RnExArJNb@EFEFQF?VHrDH`@HLITHn@FvAIb@@l@PbADlHp@bETzENfA?nAGt@MNKl@m@jAuAr@aAd@[x@cAXWdDsDtBwBvJwKpCyCb@i@|AcBnA{AZSJBNX~@v@r@h@zBpAXTNRDLB\\F|DHbC@x@CnADVVNbCzCxA|Aj@r@VT^f@RJV`@`A`AXb@p@v@PZDBr@t@NAHQ`EsL\\}AXoCDEHDNh@vAnDLf@tDvIxEdLNb@xCzGhIvRrEpHlDjE^^T@PQhHiU|@kCp@}Bf@uAXg@TGJ@x@d@rEhD~A`AZZLXJn@@`@A`@Il@UpCc@|DKh@a@lASfAA`@c@jEO~B@nA^lMDZFF~Gf@xGr@pNpAlDd@fATZLDH@LALIXe@zG_@|DAl@[tDObDe@bII`@QXoC`C{@`Aq@lAWr@Oh@Kj@[xCaDx[MzBGlEEbAk@lGSx@e@v@OPcAn@s@~@s@pBMp@a@fHQfBkB~FmBrGUf@Ol@aBdF_B`Fe@`BcCzHORIBSG_@]GAGNk@jDuBbKGHG@m@a@eMwGCKAQZgADEFAXVZPF??HAAFH?GAH?WEFCMB?ALD@EE");

// [lat, long][]
// [y, x]
// Wroclaw sample: 51.1079° N, 17.0385° E

type ParsedPoints = [number, number, number];

function getCenterPoint(mesh: Mesh) {
  const geometry = mesh.geometry;
  geometry.computeBoundingBox();
  const center = new Vector3();
  geometry.boundingBox!.getCenter(center);
  mesh.localToWorld(center);
  return center;
}

function reduceLocations(locations: ParsedPoints[], minDistance: number) {
  let reducedLocations = [];
  let lastLocation = locations[0];
  let lastAngle = 0;

  for (let i = 1; i < locations.length; i++) {
    let location = locations[i];
    let angle = calculateAngle(lastLocation, location);
    if (
      calculateDistance(lastLocation, location) >= minDistance ||
      angle - lastAngle > 35
    ) {
      reducedLocations.push(location);
      lastLocation = location;
      lastAngle = angle;
    }
  }

  return reducedLocations;
}



function calculateAngle(location1: ParsedPoints, location2: ParsedPoints) {
  let xDiff = location2[0] - location1[0];
  let yDiff = location2[1] - location1[1];
  return (Math.atan2(yDiff, xDiff) * 180) / Math.PI;
}

function calculateDistance(location1: ParsedPoints, location2: ParsedPoints) {
  let xDiff = location2[0] - location1[0];
  let yDiff = location2[1] - location1[1];
  return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
}

type Props = {
  data: PointData[];
  rendererRef: any;
  tiles: PointData[][];
  generatingVideo?: boolean;
  autoDownload?: boolean;
  onVideoFinish?: () => void;
  drawSurface?: boolean;
  drawLines?: boolean;
  drawLabels?: boolean;
  theme?: Theme;
  nearestCities?: City[];
};

let frame = 0

const Vizualization = ({
  data,
  rendererRef,
  tiles,
  generatingVideo = false,
  autoDownload = true,
  onVideoFinish,
  drawSurface = true,
  drawLines = true,
  drawLabels = true,
  theme = themes[0],
  nearestCities = [],
}: Props) => {
  const sphere = React.useRef<Mesh>(null!);
  const [pathRef, setPathRef] = React.useState<Mesh>(null!);
  const textRef = React.useRef<Mesh>(null!);
  const cameraRef = React.useRef<RawPerspectiveCamera>(null!);
  const gl = useThree((state) => state.gl);
  const encoderRef = React.useRef<Encoder | null>(null);
  const controlsRef = React.useRef(null!);
  const [positonZ, setPositionZ] = React.useState(0);
  const centerPoint = useMemo(() => {
    if (!pathRef || !pathRef.geometry) {
      return
    }
    return getCenterPoint(pathRef);
  }, [pathRef]);


  const zoomFactor = useMemo(() => {
    return Math.abs((positonZ))
  }, [positonZ])


  useEffect(() => {
    rendererRef.current = gl;
  }, [gl, rendererRef]);

  const parsedTiles = useMemo(
    () =>
      tiles.map((row) => {
        return row.map((point) => {
          const data = [
            point.location.lat * 100000,
            point.location.lng * 100000,
          ];
          const parsedData = [data[0], data[1], point.elevation * 10];
          return parsedData as ParsedPoints;
        });
      }),
    [tiles, theme]
  );

  useEffect(() => {
    if (typeof VideoEncoder === "undefined" || !generatingVideo) {
      return;
    }
    encoderRef.current = new Encoder({
      canvas: rendererRef.current!.domElement,
      duration: fullTime,
      framerate: 60,
      videoBitrate: 10_000_000,
      autoDownload,
    });

    encoderRef.current.prepare();
  }, [generatingVideo]);

  const parsed = useMemo(() => {
    const maped = data.map((point) => {
      const data = [point.location.lat * 100000, point.location.lng * 100000];
      const parsedData = [data[0], data[1], point.elevation * 10];
      return parsedData as ParsedPoints;
    });
    return reduceLocations(maped, 6);
  }, [data]);

  const mappedToPoints = useMemo(
    () =>
      parsed.map(
        ([lat, long, alt]) =>
          new Vector3(Math.floor(lat), Math.floor(alt) + 2, Math.floor(long))
      ),
    [parsed]
  );

  const citiesMappedToPoints = useMemo(
    () =>
      nearestCities.map(
        ({ location, elevation }: any) => {
          const { lat, lng } = location;
          return new Vector3(Math.floor(lat * 100000), elevation * 10, Math.floor(lng * 100000))
        }
      ),
    [nearestCities])

  const tilesMappedToPoints = useMemo(
    () =>
      parsedTiles.map((row) =>
        row.map(
          ([lat, long, alt]) => {
            return new Vector3(
              Math.floor(lat),
              Math.floor(alt),

              Math.floor(long)
            )
          }
        )
      ),
    [parsedTiles, drawSurface, drawLines]
  );

  const tilesMappedToPoints2 = useMemo(() => {
    return tilesMappedToPoints[0].map((_, index) =>
      tilesMappedToPoints.map((row) => row[index])
    );
  }, [tilesMappedToPoints]);

  const fullTime = 15;
  const fullCameraTime = 45;

  useFrame(async ({ clock, camera }) => {
    if (camera.position.z !== 0 && !positonZ) {
      setPositionZ(-camera.position.distanceTo(centerPoint));
    }

    if (generatingVideo) {
      frame = frame + 1;
    }

    const rotateToAngle = (angle: number) => {
      let currentRotation = (controlsRef.current as any).getAzimuthalAngle();
      currentRotation = (currentRotation + Math.PI * 2) % (Math.PI * 2);
      const rotationDelta = angle + currentRotation;

      (controlsRef.current as any).rotateLeft(rotationDelta);
    };
    const elapsed = clock.getElapsedTime();
    const currentLoopTime = elapsed % (fullTime);
    const currentCameraLoopTime = elapsed % (45);
    const percentage = generatingVideo ? (((frame / (60 * fullTime))) % 1) : currentLoopTime / (fullTime);
    const cameraPercentage =  generatingVideo ? (((frame / (60 * fullCameraTime))) % 1) : currentCameraLoopTime / (fullCameraTime);
    rotateToAngle(angleToRad(cameraPercentage * 360));
    if (sphere.current) {
      if (textRef) {
        textRef.current.lookAt(camera.position);
        textRef.current.text = `${Math.floor(
          data[Math.floor(percentage * data.length)].elevation
        )} m`;
      }

      sphere.current.position.set(
        ...pathCurve.getPointAt(percentage).toArray()
      );

      // video generation
      if (generatingVideo && encoderRef.current) {
        const finished = await encoderRef.current!.addFrame();
        if (finished && onVideoFinish) {
          onVideoFinish();
          if (!autoDownload) {
            const stringifiedBlob =
              await encoderRef.current.getStringifiedBlob();
            (window as any).onRenderFinish(stringifiedBlob);
          }
        }
      }
    }
  });
  const pathCurve = useMemo(() => {
    return new CatmullRomCurve3(mappedToPoints, false, "centripetal");
  }, [mappedToPoints]);
  const gridFilterFactor = useMemo(() => {
    return Math.max(Math.floor(positonZ / 34300), 1);
  }, [positonZ]);
  return (
    <>
      {data.length && (
        <>
          <PerspectiveCamera
            position={[857, 400, 0]}
            makeDefault
            fov={20}
            near={400}
            far={21000}
            ref={cameraRef}
          />
          <color attach="background" args={[`#${theme.background}`]} />
          <OrbitControls
            enableDamping={false}
            minPolarAngle={0.9}
            maxPolarAngle={0.9}
            target={pathRef ? centerPoint : undefined}
            minDistance={500}
            maxDistance={3000000}
            ref={controlsRef}
          />
          <Lights />
          <Bounds fit clip observe damping={0} margin={1.1}>
            <mesh>
              <Tube
                renderOrder={400}
                ref={(newRef) => setPathRef(newRef as Mesh)}
                args={[pathCurve, 1000, positonZ / 2000, 3]}
              >
                <meshStandardMaterial attach="material" color={`#${theme.path}`} depthWrite={false} metalness={.2} roughness={.5} />
              </Tube>
            </mesh>
          </Bounds>
          <PanthEnd position={mappedToPoints[0]} zoomFactor={zoomFactor} theme={theme} />
          <PanthEnd position={mappedToPoints[mappedToPoints.length - 1]} zoomFactor={zoomFactor} theme={theme} />
          {drawLines && <Grid tilesMappedToPoints={tilesMappedToPoints} tilesMappedToPoints2={tilesMappedToPoints2} gridFilterFactor={gridFilterFactor} theme={theme} />}
          {nearestCities && drawLabels && <Cities citiesMappedToPoints={citiesMappedToPoints} nearestCities={nearestCities} theme={theme} zoomFactor={zoomFactor} />}
          <PositionPointer zoomFactor={zoomFactor} theme={theme} passRef={sphere} passTextRef={textRef} />
          {pathRef && drawSurface && centerPoint && <Surface position={centerPoint} tilesMappedToPoints={tilesMappedToPoints} tilesMappedToPoints2={tilesMappedToPoints2} theme={theme} drawLabels={drawLabels} />}
        </>
      )}
    </>
  );
};

export default Vizualization;
