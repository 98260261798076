import { Theme } from '../../../themes'
import { Vector3 } from 'three'

type Props = {
    theme: Theme
    zoomFactor: number
    position: Vector3
}

const PanthEnd = ({ theme, zoomFactor, position }: Props) => {
    return (
        <mesh position={position} renderOrder={400}>
        <cylinderGeometry args={[(zoomFactor / 300), (zoomFactor / 300), zoomFactor / 2000, 20]} />
        <meshStandardMaterial attach="material" color={`#${theme.marks}`} depthWrite={false} metalness={.2} roughness={2} />
      </mesh>
    )
}

export default PanthEnd
