/** @jsxImportSource @emotion/react */

import Sidebar from "./components/Sidebar";

import Activities from "./Activities";
import { useEffect, useState } from "react";
import { Activity, RequestVideoResponse } from "types";
import StartScreen from "./StartScreen";
import ActivityComponent from "./components/Activity";
import StravaTrademark from "./components/StravaTrademark";
import { useToastContext } from "./components/Toasts";

import { css } from "@emotion/react";

import { getActivities, getVideos, getUser } from "./api";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const containerStyle = css`
  display: flex;
  align-items: stretch;
  height: 100%;
`;

const infoToast = css`
  position: fixed;
  width: 90%;
  max-width: 500px;
  border-radius: 10px;
  background-color: #fff;
  left: 10px;
  right: 10px;
  z-index: 20;
  margin: 10px auto;
  text-align: center;
  padding: 10px;

  @media (max-width: 740px) {
    top: 70px;
  }
`;

const isAuthPage = window.location.href.includes("/auth");

const navigateToAuthPage = () => {
  window.location.href = process.env.REACT_APP_WEBAPP_ADDRESS + "/auth";
};

const checkVideoStatus = async (
  activityId: number,
  counter: number = 0
): Promise<RequestVideoResponse | false> => {
  if (counter > 26) {
    return false;
  }
  try {
    const data = await getVideos(activityId);
    const status = data?.videos && data.videos[0] && data?.videos[0].status;
    if (status === "DONE") {
      return data?.videos[0];
    }
    if (status === "ERROR") {
      return data?.videos[0];
    }
    await sleep(10000);
    return checkVideoStatus(activityId, counter + 1);
  } catch {
    return false;
  }
};

function findPrevious(id: any, items: Array<any>) {
  for (let i = 1; i < items.length; i++) {
    if (items[i].id === id) {
      return items[i - 1];  // Returns the previous item
    }
  }
  return null;  // Returns null if no previous item or item not found
}

function findNext(id: any, items: Array<any>) {
  for (let i = 0; i < items.length - 1; i++) {
    if (items[i].id === id) {
      return items[i + 1];  // Returns the next item
    }
  }
  return null;  // Returns null if no next item or item not found
}

function App() {
  const [activities, setActivities] = useState<Activity[] | null>(null);
  const addToast = useToastContext();

  const [activeActivityId, setActiveActivityId] = useState<number | null>(null);
  const [currentActivityPage, setCurrentActivityPage] = useState<number>(1);
  const [loadingActivities, setLoadingActivities] = useState<boolean>(false);
  const [hasMoreActivities, setHasMoreActivities] = useState<boolean>(true);
  const [rateLimit, setRateLimit] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>(null);

  const [showMenu, setShowMenu] = useState(false);

  const addVideoToChecker = (activityId: number, clickHandler: () => void) => {
    return checkVideoStatus(activityId).then((response) => {
      if (Boolean(response)) {
        addToast({
          message: "Your video is ready!",
          type: "success",
          id: String(Math.random()),
          handler: () => {
            setActiveActivityId(activeActivityId);
            clickHandler()
          },
          label: "View",
        });
      } else {
        addToast({
          message: "Creating Video failed.",
          type: "error",
          id: String(Math.random()),
        });
      }
      return response;
    });
  };

  useEffect(() => {
    if (isAuthPage || loadingActivities) {
      return;
    }
    setLoadingActivities(true);
    getUser().then((data) => {
      setUserData(data);
    });
    getActivities(currentActivityPage)
      .then((data) => {
        setActivities([...(activities || []), ...data.activities]);
        if (!activeActivityId) {
          setActiveActivityId(data.activities[0].id);
        }
        if (data.activities.length === 0) {
          setHasMoreActivities(false);
        }
      })
      .catch((error) => {
        if (error.message === "Rate Limit") {
          setRateLimit(true);
        } else {
          navigateToAuthPage();
        }
      })
      .finally(() => {
        setLoadingActivities(false);
      });
  }, [currentActivityPage]);

  const handleActivityClick = (id: number) => {
    setShowMenu(false);
    setActiveActivityId(id);
  };

  return (
    <div css={containerStyle}>
      {rateLimit && (
        <div css={infoToast}>
          App has exceeded the rate limits to the Strava API.
          <br />
          Please try again in a few minutes.
        </div>
      )}
      {activities && (
        <Sidebar
          showMenu={showMenu}
          onMenuButtonClick={() => setShowMenu(true)}
        >
          <Activities
            onMoreItemsRequest={() => {
              if (!loadingActivities) {
                setCurrentActivityPage(currentActivityPage + 1);
              }
            }}
            hasMoreItems={hasMoreActivities}
            loading={loadingActivities}
            activities={activities}
            activeActivityId={activeActivityId}
            onActivityClick={handleActivityClick}
          />
        </Sidebar>
      )}
      {isAuthPage && <StartScreen />}
      {!isAuthPage && activeActivityId && (
        <ActivityComponent
          userData={userData}
          addVideoToChecker={(callback: () => void) => addVideoToChecker(activeActivityId, callback)}
          activityId={activeActivityId}
          handlePreviousClick={() => {
            const previous = findPrevious(activeActivityId, activities!)
            if (previous) {
              setActiveActivityId(previous.id)
            }
          }}
          handleNextClick={() => {
            const next = findNext(activeActivityId, activities!)
            if (next) {
              setActiveActivityId(next.id)
            } else {
              if (!loadingActivities) {
                setCurrentActivityPage(currentActivityPage + 1);
              }
            }
          }}
        />
      )}
      <StravaTrademark />
    </div>
  );
}

export default App;
