/** @jsxImportSource @emotion/react */
import {
  useCallback,
  useContext,
  useState,
  createContext,
  useEffect,
} from "react";
import { css } from "@emotion/react";
import Done from "./Done";

import { commonButtonStyle } from "../commonStyles";

const ToastContext = createContext<(toast: Toast) => void>(() => {});

export default ToastContext;

type Toast = {
  id: string;
  message: string;
  type: "success" | "error" | "info";
  label?: string;
  handler?: () => void;
};

const toastsWrapperStyle = css`
  position: fixed;
  top: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  row-gap: 2px;
`;

const toastStyle = css`
  font-size: 14px;
  line-height: 1.4em;
  background-color: #fff;
  border-radius: 10px;
  padding: 3px 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.8);
`;

const buttonStyle = css`
  ${commonButtonStyle(false, true)};
  cursor: pointer;
  padding: 4px;
  border-radius: 10px;
`;

export function ToastContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [toasts, setToasts] = useState<Toast[]>([]);

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(
        () => setToasts((toasts) => toasts.slice(1)),
        9000
      );
      return () => clearTimeout(timer);
    }
  }, [toasts]);

  const addToast = useCallback(
    function (toast: Toast) {
      setToasts((toasts) => [...toasts, toast]);
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <div css={toastsWrapperStyle}>
        {toasts.map((toast) => (
          <div css={toastStyle} key={toast.id}>
            {toast.type === "success" && <Done />}
            {toast.message}
            {toast.label && toast.handler && (
              <button onClick={toast.handler} css={buttonStyle}>
                {toast.label}
              </button>
            )}
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
}

export function useToastContext() {
  return useContext(ToastContext);
}
