import { Theme } from '../../../themes'
import {
    Text,
} from "@react-three/drei";

type Props = {
    theme: Theme
    zoomFactor: number
    passRef: React.MutableRefObject<any>
    passTextRef: React.MutableRefObject<any>
}

const PositionPointer = ({ theme, zoomFactor, passRef, passTextRef }: Props) => {
    return (
        <mesh ref={passRef} renderOrder={1000}>
            <Text
                lineHeight={2.5}
                fontSize={zoomFactor / 130}
                color={`#${theme.text}`}
                ref={passTextRef}
                anchorY="bottom"
                font="/Inter-Regular.woff"
            >
                .
            </Text>
            <sphereGeometry args={[zoomFactor / 350, 20, 20]} />
            <meshStandardMaterial attach="material" color={`#${theme.dot}`} depthWrite={false} metalness={.5} roughness={.9} />
        </mesh>
    )
}

export default PositionPointer
