export const formatDistance = (distance: number) => {
  return `${(distance / 1000).toFixed(2)} km`;
};

export const formatMovingTime = (movingTime: number) => {
  const hoursPart =
    movingTime > 3600 ? `${Math.floor(movingTime / 3600)}h ` : "";
    const minutesPart = movingTime > 3600 ? `${Math.floor(movingTime / 60) - Math.floor(movingTime / 3600) * 60}m ` : `${Math.floor(movingTime / 60)}m `;
    const secondsPart = movingTime > 3600 ? '' : `${movingTime - Math.floor(movingTime / 60) * 60}s`;
  return `${hoursPart} ${minutesPart} ${secondsPart}`;
};
