/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRef } from "react";

type Props = {
    onEmailClick: (email: string) => void;
    email?: string;
    onCloseClick: () => void;
}

const containerStyle = css`
    padding: 10px;
`

const inputStyle = css`
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    font-family: inherit;
    color: inherit;
    padding: 5px 10px;
`

const buttonStyle = css`
    border: 0;
    background-color: #7158D3;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
`

const buttonContainer = css`
    display: flex;
    align-items: stretch;
`

const emailPrefilledButtonContainer = css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const EmailNotificationForm = ({ onEmailClick, email, onCloseClick }: Props) => {
    const emailInputRef = useRef<HTMLInputElement>(null);
    const handleSendEmailClick = () => {
        if (emailInputRef.current) {
            const email = emailInputRef.current.value;
            onEmailClick(email)
        }
    }

    return (
        <div css={containerStyle}>
            <p>We are preparing your animation. This usually takes around 4 minutes.</p>
            <p>You can leave your email address and we will send you a notification when video is ready.</p>
            {!email &&
                <form css={buttonContainer} onSubmit={handleSendEmailClick}>
                    <input css={inputStyle} type="email" placeholder="Email address" ref={emailInputRef} />
                    <button type="submit" css={buttonStyle}>Notify me</button>
                </form>
            }
            {email && <div>
                <p>We will notify you at {email}</p>
                <div css={emailPrefilledButtonContainer}>
                    <button onClick={onCloseClick} css={buttonStyle}>OK</button>
                </div>
            </div>}
        </div>
    )
}

export default EmailNotificationForm
